<template>
  <v-app-bar
    :app="isMobile"
    
    :fixed="isMobile"
    color="white"
    class="mb-2"
    elevation="2"
    v-show="showappbar == true && device == 'Mobile'"
  >
    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
    <!-- <v-btn @click="ChangeURL('')" icon><v-icon color="red darken-4">mdi-home</v-icon></v-btn> -->
    <v-btn @click="ChangeURL('')" icon><v-img src="@/assets/logo-honda-1.png" max-width="37" class="ml-2"></v-img></v-btn>

    <v-toolbar-title class="mx-n4"><span class="toolbar-text text-h6"></span></v-toolbar-title>

    <v-spacer></v-spacer>
     <span class="toolbar-text" v-show="userlogged" v-text="userlogged != null ? userlogged.nama_lengkap : null"></span>
    <v-menu
    :rounded="true"
    offset-y
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn 
      v-bind="attrs"
      v-on="on"
      icon>
          <v-icon color="red darken-4">mdi-account</v-icon>
      </v-btn>
    </template>

    <!-- <v-list>
      <v-list-item
        v-for="acc in accitem"
        :key="acc.text"
        :to="acc.to"
        link
      >
        <v-list-item-title @click="ChangeURL(acc.to)" v-text="acc.text"></v-list-item-title>
      </v-list-item>
    </v-list> -->
  </v-menu>
  </v-app-bar>
</template>
<script>
// import firebase from '@/services/firebase-sw.js'
import Vue from 'vue'
export default {
  data: () => ({
  dialog:true,
  device:null,
  isMobile:false,
  department:null,
  UserPengguna:null,
  showappbar:false,
  userlogged:localStorage.getItem('logged'),
  accitem: [
      {
          to:'auth',
          text:'Logout'
      },
      {
          to:'setting',
          text:'Pengaturan'
      }
    ],
  }),
  mounted(){
    console.log('mobile bar')
      this.device = this.$device
      if(this.device == 'Mobile'){
        this.isMobile = true
      }
      this.setLoggedMobile()
      Vue.prototype.$setLoggedMobile = this.setLoggedMobile
      Vue.prototype.$LogOutMobile = this.LogOutMobile
  },
  methods:{
    setLoggedMobile(){
      this.userlogged = JSON.parse(localStorage.getItem('logged'))
      if(this.userlogged != null){
        this.department = this.userlogged.department
        this.showappbar = true
      }
    },
    LogOutMobile(){
      console.log('logout')
      this.$loading(true)
      this.showappbar = false
      // let db = firebase.database()
      // console.log('firebase delete')
      let u = JSON.parse(localStorage.getItem('logged'))
      // this.$Logger(JSON.parse(localStorage.getItem('logged')),'Log Out')
      localStorage.setItem('logouthai',JSON.stringify(u))
        localStorage.removeItem('logged')
        this.$router.push('/auth')
    },
    ChangeURL(x){
      if(x == 'auth'){
        return this.LogOut()
      }else{
        return this.$ChangeURL(x)
      }
    }, 
  }
}
</script>